<template>
    <div class="wrapper">
            <div class="container-one">
                <div class="profile">
                    <div class="profile-image-container">
                        <img v-if="!isMobileScreen" src="../assets/ProfessionalProfilePictureVue.png" alt="My best impression of a big red truck">
                        <img v-else src="../assets/SmartphoneProfilePic.png" alt="Alternative Profile Picture for smaller Screens">
                    </div>
                    <h2>Isaac Greenwood</h2>
                    <h3>Software Developer</h3>
                </div>
                <div class="social-links">
                 <a href="https://github.com/igreenwood1985" target="blank">
                     <div class="link">
                            <img src="../assets/GitHubBW.png" alt="Github Logo from pngimg.com">
                           <!-- <h2>GitHub</h2> -->
                        </div>
                </a>
                <a href="https://www.linkedin.com/in/isaacgreenwood-developer/" target="blank">
                        <div class="link">
                            <img src="../assets/LinkdInBW.png" alt="LinkdIn Logo from pngimg.com">
                            <!-- <h2>LinkdIn</h2> -->
                        </div>
                </a>
                <a href="https://www.youtube.com/@gensanityvlog2932" target="blank">
                        <div class="link">
                            <img src="../assets/YouTubeBW.png" alt="Youtube Logo from pngimg.com">
                            <!-- <h2>Youtube</h2> -->
                        </div>
                </a>
                <a href="https://www.instagram.com/isaac.greenwood.73/" target="blank">
                        <div class="link">
                            <img src="../assets/InstagramBW.png" alt="Instagram Logo from pngimg.com">
                            <!-- <h2>Instagram</h2> -->
                        </div>
                </a>
            
                    <p class = "logo-copyright-notice">Icons by <a href ="https://pngimg.com/" target="blank">pngimg.com</a></p>
                </div>
            </div>
            <div class="container-two">
                <div class="about-me">
                    <h1>About Me</h1>
                    <p>
                        Hi I'm Isaac! 

                        I have spent significant time living in the Philippines 
                        and exploring Southeast Asia. My professional experience includes a strong background 
                        in learning and management positions. My time managing a team of competitive 
                        delivery drivers for a local delivery app and my travels to unfamiliar places where 
                        I have met many unique individuals and experienced several unfamiliar cultures has 
                        helped me to gain unique and diverse perspectives. I have collected many ideas and 
                        concepts in the process. Through this journey and adventure, I was led to embark on 
                        a new journey of expanding my knowledge in Tech so that I can help to create the 
                        practical side of what I love most- collecting, learning and sharing knowledge. 
                        In addition to traveling I also collect cultural artifacts and fossils 
                        which I learn everything about, to share and show to those around me!

                        My mission is to implement my collected knowledge and experiences as a Software developer 
                        to enhance other's perspectives of the world and through practical applications driven 
                        by my unique insights. I am looking forward to learning more and connecting with you!
                    </p>
            </div>
        
            <div class="technologies">
                <h1>Primary Technologies</h1>
                <div class="logos">
                    <img src="../assets/Junit-cleanpng.png" alt="">
                    <img src="../assets/IntelliJ-cleanpng.png" alt="">
                    <img src="../assets/VsCode-cleanpng.png" alt="">
                    <img src="../assets/vueColor-cleanpng.png" alt="">
                    <img src="../assets/java-cleanpng.png" alt="">
                    <img src="../assets/Javascript-cleanpng.png" alt="">
                    <img src="../assets/HTML5Logo-cleanpng.png" alt="">
                    <img src="../assets/CSSLogo-cleanpng.png" alt="">
                    <img src="../assets/PostgreSQL-cleanpng.png" alt="">
                    <img src="../assets/git-cleanpng.png" alt="">
                    <img src="../assets/Postman-cleanpng.png" alt="">
                    <img src="../assets/Springboot-cleanpng.png" alt="">
                </div>
                <p class = "logo-copyright-notice">Logos by <a href ="https://cleanpng.com/" target="blank">cleanpng.com</a></p>
            </div>
   </div>

    </div>
   
</template>

<script>
export default {
  data() {
    return {
      isMobileScreen: false,
    };
  },
  mounted() {
    this.isMobileScreen = window.innerWidth < 650;
    window.addEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      this.isMobileScreen = window.innerWidth < 650;
    },
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  },
}
</script>

<style scoped>

    h1 {
        color:#743030;
    }

    h2 {
        color:#743030
    }

    .profile h3 {
        color: #353333;
    }
    .wrapper {
        display: flex;
        justify-content: center;
    }
    .main-container {
        display: flex;
        flex-direction: row;
        background-color: #743030;
        border-radius: 2rem;
        width: 60%;
        box-shadow: #353333 0px 3px 8px;
        
    }

    .logos {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
    }
    .container-one {
        background-color: #d3a007;
        border-radius: 2rem;
        padding: 2.5%;
        width: 40%;
        box-shadow: #353333 0px 3px 8px;
        align-items: center;
    }
    .profile img {
        max-height: 20rem;
        border-radius: 5rem;
    }

    .link img {
        margin: 3rem;
        height: 5rem;
    }

    .link img:hover {
        height: 5.25rem;
    }
    .container-two {
        background-color: #debe5c;
        border-radius: 2rem;
        margin: 2.5%;
        padding: 2.5%;
        width: 60%;
        box-shadow: #a9a1a1 0px 3px 8px;
    }

    .about-me {
        text-align: left;
    }

    .technologies {
        background-color: #f5f5dc;
        border-radius: 1rem;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        box-shadow: #353333 0px 3px 8px;
        
    }

    .logos img {
        height: 3rem;
        margin: 5%;
    }

    .button-container {
        margin-top: 5%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
    .projects-button {
        background-color: #743030;
        padding: 5px;
        border-radius: 15px;
        margin: 5px;
        box-shadow: #353333 0px 3px 8px;
    }
    .projects-button a {
        text-decoration: none;
        font-weight: bold;
        color: #f5f5dc;
    }

    .about-me p {
       color: #353333;
    }

    @media only screen and (max-width: 1200px) {
        .profile img {
            max-height: 15rem;
            border-radius: 3.5rem;
        }
    }

    @media only screen and (max-width: 1000px) {
        .profile img {
            max-height: 10rem;
            border-radius: 2rem;
        }
    }

    @media only screen and (max-width: 750px) {
        .profile img {
            max-height: 7rem;
            border-radius: 1.5rem;
        }
    }

    @media only screen and (max-width: 650px) {
        .profile-image-container {
            overflow: hidden;
            height: 200px; /* Adjust the height as needed */
            border-radius: 1rem; /* Optional: to make the container circular */
        }

        .social-links {
            display: block;
            justify-content: center;
        }

        .link {
            margin: 0 -20px;
        }
    }
</style>
<template>
  <MainCard/>
</template>

<script>
// @ is an alias to /src
import MainCard from '../components/MainCard.vue'

export default {
  name: 'HomeView',
  components: {
    MainCard
  }
}
</script>

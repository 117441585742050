<template>
    <header>
        <div class="logo-container">
          <img src = "./assets/GensanityDevLogo.png" alt = "main logo"/>
          <h1>Gensanity Dev</h1>
        </div>
        <button class="nav-toggle" aria-label="toggle navigation">
            <span class="hamburger"></span>
        </button>
        <nav>
          <router-link class = "topbar" to="/">Home</router-link> 
          <router-link class = "topbar" to="/resume">Resume</router-link>
          <router-link class = "topbar" to="/projects">Projects</router-link>
          <router-link class = "topbar" to="/contact">Contact</router-link> 
          <router-link class = "topbar" to="/travel">Travel Blog</router-link>
        </nav>
      </header>
      <body>
        <router-view/>
      </body>
</template>

<script>
document.addEventListener('DOMContentLoaded', () => {
    const navToggle = document.querySelector('.nav-toggle');
    const nav = document.querySelector('nav');

    navToggle.addEventListener('click', () => {
        nav.classList.toggle('nav-visible');
        navToggle.classList.toggle('nav-visible');
    });
});

document.addEventListener('scroll', () => {
    const header = document.querySelector('header');

    if (window.scrollY > 0) {
        header.classList.add('scrolled');
    } else {
        header.classList.remove('scrolled');
    }
});

</script>


<style>
body {
  top: 0;
}

#app {
  font-family: Avenir, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: black;
  margin-top: 60px;
  background-color: antiquewhite;
}

header {
  z-index: 1000; /*ensures header navbar glides over all other elements during scrolling */
  background-color:#743030;
  height: 3.5rem;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: fixed;
  top: 0;
  transition: background-color 0.4s ease-in-out;
}

header.scrolled{
  background-color: #743030;
  opacity: 0.85;
}

header nav {
  padding: 1.5rem 0 1.5rem 5rem;
}

header img {
  height: 3rem;
  z-index: 1002;
}

.topbar {
  text-decoration: none;
  font-size: 1.3rem;
  margin: 1rem;
  color: white;
  transition: color 0.4s ease-in-out;
}


.topbar-scrolled {
  color: #743030; /*set background color of nav bar*/
}

.logo-container {
  display: flex; /* Ensure this is a flex container */
  justify-content: flex-start; /* Aligns children (logo) to the start */
  align-items: center; /* Centers logo vertically */
  flex-grow: 1; /* Allows the container to grow, taking necessary space */
  height: 100%; /* Match parent's height for vertical centering */
  margin-left: 1rem;
}

.logo-container img {
  border-radius: 5px;
}

.logo-container h1 {
  color: antiquewhite;
  font-family: 'Courier New', Courier, monospace;
  margin-left: 1rem;
  z-index: 1002;

}

nav {
  padding: 30px; /*add padding for readability*/
}

nav a {
  font-weight: normal; /*set the font weight of the title text of the router links in the nav bar */
}

nav a.router-link-exact-active {
  color: #f9b3a7; /*apply color to the navbar title text of the page that is currently active */
}

nav a:hover {
  color: lightskyblue;
}
/** section added to css to implement hamburger on mobile devices */
.nav-toggle {
    z-index: 1001;
    background: none;
    border: none;
    cursor: pointer;
    display: none;
    position: absolute;
    top: 10px;
    right: 10px;
}

.hamburger,
.hamburger::before,
.hamburger::after {
    content: '';
    display: block;
    background-color: white;
    height: 2px;
    width: 25px;
    border-radius: 10px;
    transition: all 0.3s ease-in-out;
}

.hamburger::before {
    transform: translateY(-8px);
}

.hamburger::after {
    transform: translateY(6px);
}

@media only screen and (max-width: 1200px) {
  .logo-container h1 {
    font-size: small;
  }
}

/** section added to css to implement hamburger on mobile devices */

@media only screen and (max-width: 750px) {

  .logo-container {
    width: 25%;
  }

  nav a {
    font-size: smaller; /*adjust font size for smartphone screen */
    flex-wrap: wrap;
  }

/** section added to @media to implement hamburger on mobile devices */
.nav-toggle {
        margin-top: 1rem;
        display: block;
    }

    nav {
        margin: 200px 10px 0px 120px; /**sets the size of the field for the toggle-menu */
        display: none;
        flex-direction: column;
        align-items: flex-end;
        width: 100%;
        background-color:#743030;
        opacity:80%;
    }

    .nav-visible {
        display: flex;
    }

    .topbar {
        font-size: 1rem;
        margin: 0.25rem;
    }

    header {
        justify-content: space-between;
    }

    body {
      margin: 60px 10p;
    }
/** section added to @media to implement hamburger on mobile devices */
}

@media only screen and (max-width: 650px) {
  header img {
    height: 1.5rem;
  }

  .logo-container {
    width: 0%;
  }
}

</style>